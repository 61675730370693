<template>
	<div class="assessBox">
		<div class="app-container">
			<div class="titlebox">
				<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>

				<div class="title">{{ dimission && dimission.name }}-离职信息详情</div>
			</div>
			<div class="picbox">
				<div>
					<el-avatar :size="100" :src="dimission && dimission.avatarUrl"></el-avatar>
				</div>
				<div>
					<h2>{{ dimission && dimission.name }}</h2>
					<p>{{ dimission && dimission.jobName }}&nbsp;&nbsp;{{ dimission && dimission.orgName }}</p>
					<p>
						<span>手机号码：{{ dimission && dimission.phoneNumber }}</span>
						<span>入职时间：{{ dimission && dimission.entryTime }}</span>
					</p>
				</div>
			</div>
			<div class="viewbox">
				<div>
					<div style="width: 100%" class="dimissionInfo">
						<div>
							<div>
								<label class="left_label">离职类型</label>
								<label class="right_label">
									<div class="inputDiv">{{ dimission && dimission.dimissionTypeName }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">预计离职时间</label>
								<label class="right_label">
									<div class="inputDiv">{{ dimission && dimission.dimissionDate }}</div>
								</label>
							</div>
						</div>
						<div>
							<div>
								<label class="left_label">实际离职时间</label>
								<label class="right_label">
									<div class="inputDiv">{{ dimission && dimission.affirmDimissionDate }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">离职原因</label>
								<label class="right_label">
									<div class="inputDiv">{{ dimission && dimission.dimissionRemarkName }}</div>
								</label>
							</div>
						</div>
						<div>
							<div>
								<label class="left_label">离职原因详情</label>
								<label class="right_label">
									<div class="inputDiv">{{ dimission && dimission.applyDimissionRemark }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">补偿金</label>
								<label class="right_label">
									<div class="inputDiv">{{ Number(dimission.compensation) > 0 ? dimission && dimission.compensation : '' }}</div>
								</label>
							</div>
						</div>
						<div>
							<div>
								<label class="left_label">社保减员月</label>
								<label class="right_label">
									<div class="inputDiv">{{ dimission && dimission.socialSecurityReductionMonth }}</div>
								</label>
							</div>
							<div>
								<label class="left_label">公积金减员月</label>
								<label class="right_label">
									<div class="inputDiv">{{ dimission && dimission.providentFundReductionMonth }}</div>
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { getDimissonInfo } from '@/api/workbench';
export default {
	components: {},

	data() {
		return {
			props: {
				disabled: 'disable'
			},
			dimission: {
				default() {
					return {
						items: ''
					};
				}
			},
			id: ''
		};
	},

	created() {},
	mounted() {
		this.$nextTick(function () {
			this.id = this.$route.query.id;
			this.getDimissonInfo();
		});
	},
	methods: {
		getDimissonInfo() {
			getDimissonInfo({
				request: {
					id: this.id
				}
			})
				.then((result) => {
					this.dimission = result;
				})
				.catch(() => {});
		}
	}
};
</script>
<style lang="scss" scoped>
.assessBox .app-container .viewbox .level_box_now {
	font-weight: bold;
}
.proSelect .el-input {
	width: 100%;
}

.inputDiv {
	width: 60%;
	// height: 40px;
	// line-height: 20px;
	display: inline-block;
	font-weight: normal;
	padding: 0 15px;
	background-color: #fff;
	background-image: none;
	border-radius: 4px;
	border: none;
	text-align: left;
	margin-bottom: 0 !important;
	vertical-align: top;
}
.picbox {
	padding: 0px 0 0 100px;

	background: #fff;

	margin: 0 20px;

	display: flex;

	align-items: center;

	margin-bottom: 20px;

	height: 140px;

	:first-child {
		margin-right: 10px;
	}

	h2 {
		margin: 10px 0 0 0;
	}

	p {
		margin: 15px 0px;

		color: #999999;
	}
}

.assessBox {
	.app-container {
		color: #484848;
		padding: 0;
		background-color: #f6f7fb;
		.titlebox {
			text-align: center;
			line-height: 17px;
			background: #fff;
			font: bold 18px/25px 'Microsoft YaHei';
			padding: 14px 40px;
			> .el-button {
				float: left;
				color: #484848;
				transform: translateY(-8px);
				font-size: 16px;
			}
			text-align: center;
			line-height: 17px;
			font: bold 18px/25px 'Microsoft YaHei';
			margin: 20px;
		}
		.viewbox {
			margin: 0 20px;
			background: #ffffff;
			// min-height: 300px;
			padding: 30px 50px;
			.dimissionInfo {
				text-align: center;
				font-size: 14px;
				div {
					margin-bottom: 10px;
					div {
						width: calc(100% / 2);
						display: inline-block;
					}
				}
				.left_label {
					margin-right: 30px;
					color: #999999;
					text-align: right;
					width: 90px;
					display: inline-block;
					font-weight: normal;
				}
				.right_label {
					width: 70%;
				}
			}
		}

		.viewFoot {
			text-align: center;
			.el-button {
				margin: 15px;
				padding: 10px 45px;
			}
		}
	}
}
.titleIcon {
	display: inline-block;
	width: 8px !important;
	height: 20px;
	background: #498df0;
	border-radius: 5px;
	vertical-align: bottom;
	margin-right: 5px !important;
}
</style>
<style lang="scss">
.dimission {
	.el-input {
		width: 100% !important;
	}
}
.jobSelect .el-input--suffix {
	width: 100% !important;
}
</style>
